<template>
   <div if="permissions.scope === 'fullaccess:staff'">
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-row align="center" justify="center">
        <v-col cols="8">
            <base-material-card
                icon="mdi-currency-usd"
                title="Update Breakdown"
                class="px-5 py-3"
            >
                
                <v-row>
                  <v-col cols="12" md="4">
                    <v-select label="Development" 
                    :items="cost_breakdown_developments" 
                    item-text="Description" 
                    item-value="DevelopmentId"
                    v-model="selected_cost_breakdown_development"
                    @change="load_plans"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select label="Plan" 
                    :items="cost_breakdown_dev_plans"
                    item-text="Description" 
                    item-value="PlanId"
                    v-model="selected_cost_breakdown_plan"
                    @change="clear_records"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn color="primary" :disabled="!selected_cost_breakdown_plan" v-on:click="load_template_values"> Load</v-btn>
                  </v-col>   
                </v-row>

                <v-data-table
                :headers="StaffHeaders"
                :items="cost_breakdown"
                disable-pagination
                :hide-default-footer="true"
                >
     
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-spacer></v-spacer>
                    <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                      <!-- TODO Uncomment if you want to add new record in the plan -->
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn  color="primary" dark class="mb-2" v-bind="attrs" v-on="on"  :disabled="!selected_cost_breakdown_plan" > New Item </v-btn>
                      </template>
                      <v-card>
                          <v-card-title>
                            <span class="card-title font-weight-light">{{ formTitle }}</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                                <v-row>
                                  <v-col
                                    cols="12"
                                    md="6"
                                  >
                                    <v-text-field
                                      v-model="selected_cost_breakdown.Description"
                                      :rules="itemRules"
                                      label="Item Description"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="6"
                                  >
                                    <v-text-field
                                      v-model="selected_cost_breakdown.Cost"
                                      :rules="costRules"
                                      label="Item Cost"
                                      type="number"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="save"
                            >
                              Save
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                      </v-card>
                          
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title >Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.cost_breakdown`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="view_breakdown(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <!-- TODO Uncomment if you want delete functionality -->
                  <v-icon
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                </v-data-table>
            </base-material-card>
            <div v-if="this.selected_cost_breakdown_development">
                <h1 right class="mr-2 v-btn__content">Total Cost: {{this.theTotalCost}}</h1>
            </div>
        </v-col>
    </v-row>
  </v-container>
  </div>
</template>
<script>
import axios from 'axios';
export default {
    name:'StaffUpdateBreakdown',
    data () {
      return {
        url:process.env.VUE_APP_URL,
        itemRules: [
          v => !!v || 'Item is required',
          v => (v && v.length <= 255) || 'Max 255 characters.',
          ],
        costRules: [
          v => (v && v.length <= 12) || 'Max 12 digits.'
        ],
        headers: [
            {
            text: 'Item',
            sortable: false,
            class:"primary--text",
            value: 'Item',
            },
            {
            text: 'Cost',
            value: 'Cost',
            sortable: false,
            class:"primary--text",
            },
        ],
        StaffHeaders: [
            {
            text: 'Item',
            sortable: false,
            class:"primary--text",
            value: 'Description',
            },
            {
            text: 'Cost',
            value: 'Cost',
            sortable: false,
            class:"primary--text",
            },
            {
            text: 'Edit',
            value: 'cost_breakdown',
            align: 'end',
            sortable: false,
            class:"primary--text",
            }
        ],
        dialog: false,
        dialogDelete: false,
        editedIndex:  -1,
        valid: false,
        cost_breakdown: [],
        theTotalCost: 0,
        selected_cost_breakdown: {
            TemplateDetailId:0,
            Description: '',
            Cost: '',
        },
        default_cost_breakdown: {
            TemplateDetailId:0,
            Description: '',
            Cost: '',
        },
        cost_breakdown_developments:[],
        selected_cost_breakdown_development:'',
        cost_breakdown_dev_plans:[],
        selected_cost_breakdown_plan:''
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
      permissions(){
        return {
          scope: localStorage.getItem("permissions")
        }
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
     
    },
    created () {
      this.initialize()
    },
    methods: {

        initialize () {
          this.get_costbreakdown_developments();
        },
        get_costbreakdown_developments(){
          axios.get(process.env.VUE_APP_GET_COSTBREAKDOWN_DEVELOPMENTS_URL,{})
          .then(response => {
            const { data } = response
            this.cost_breakdown_developments = data;
          })
          .catch(error => {
            console.log(error)
          })
          
        },
        get_costbreakdown_dev_plans(){

          axios.get(process.env.VUE_APP_GET_COSTBREAKDOWN_DEV_PLANS_URL,
          {
            params: {
              development_id: this.selected_cost_breakdown_development,
            }
          })
          .then(response => {
            const { data } = response
            data.map((element) => {
              if(element.PlanId === 81){
                element.Description = "BENTLEY III PLUS TWO STORY MODEL"
              }
            })
            this.cost_breakdown_dev_plans = data
          })
          .catch(error => {
            console.log(error)
          })

        },
        load_plans(){
          this.selected_cost_breakdown_plan = '';
          this.cost_breakdown = [];
          this.get_costbreakdown_dev_plans()
          
        },
        clear_records(){
          this.cost_breakdown=[]
        },
        load_template_values(){

          axios
          .get(process.env.VUE_APP_GET_COST_BREAKDOWN_TEMPLATE_DETAILS_URL,
          {
            params: {
              plan_id:this.selected_cost_breakdown_plan
            }
          })
          .then(response => {
            this.cost_breakdown = response.data
            this.getTheTotalCost(this.cost_breakdown)
          })
          .catch(error => {
            console.log(error)
          })

        },
        getTheTotalCost(cost_breakdown){
        let sum = 0
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        for(let i = 0; i < cost_breakdown.length; i++){
          let element = cost_breakdown[i]
          sum = sum + element.Cost
        }
        this.theTotalCost = formatter.format(sum);
        },     
        view_breakdown (cost_breakdown) {
          this.editedIndex = this.cost_breakdown.indexOf(cost_breakdown)
          this.selected_cost_breakdown = Object.assign({}, cost_breakdown)
          this.dialog = true
        },
        close(){
          this.dialog = false
          this.$nextTick(() => {
            this.selected_cost_breakdown = Object.assign({}, this.default_cost_breakdown)
            this.editedIndex = -1
          })
        },
        save(){
          var body ={
              "template_id":this.selected_cost_breakdown.TemplateDetailId,
              "description":this.selected_cost_breakdown.Description,
              "plan_id":this.selected_cost_breakdown_plan,
              "cost":this.selected_cost_breakdown.Cost
            }
          if (this.editedIndex > -1) {
            const headers = { 
                    'Content-Type': 'multipart/form-data',
                    'Authorization':'' 
                    }
            axios
            .post(process.env.VUE_APP_UPDATE_TEMPLATE_COSTBREAKDOWN_URL,body, {headers: headers})
            .then(response => {
              console.log(response.data)
            })
            .catch(error => {
              console.log(error)
            })
            Object.assign(this.cost_breakdown[this.editedIndex], this.selected_cost_breakdown)
          } else {
            axios
            .post(process.env.VUE_APP_INSERT_TEMPLATE_COSTBREAKDOWN_URL,body)
            .then(response => {
              console.log(response.data)
              this.load_template_values()
            })
            .catch(error => {
              console.log(error)
            })
          }
          this.selected_cost_breakdown = Object.assign({}, this.default_cost_breakdown)
          this.editedIndex = -1
          this.close()
        },
        deleteItem (cost_breakdown) {
          this.editedIndex = this.cost_breakdown.indexOf(cost_breakdown)
          this.selected_cost_breakdown = Object.assign({}, cost_breakdown)
          this.dialogDelete = true
        },
        deleteItemConfirm(){
          var item = this.cost_breakdown[this.editedIndex]
          var body = {"template_id":item.TemplateDetailId}
          axios
          .post(process.env.VUE_APP_DELETE_TEMPLATE_COSTBREAKDOWN_URL,body)
          .then(response => {
            console.log(response.data)
            this.load_template_values()
          })
          .catch(error => {
            console.log(error)
          })
          // this.cost_breakdown.splice(this.editedIndex, 1)
          this.closeDelete()
        },
        closeDelete(){
          this.dialogDelete = false
          this.$nextTick(() => {
            this.selected_cost_breakdown = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
    },
}
</script>
<style lang="scss" scoped>
</style>